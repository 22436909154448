import React from "react"
import { Button, Form, Input } from "antd"
import { useTranslation } from "react-i18next"

const ContactForm = ({ initialValues, onFinish, formType }) => {
  const { t } = useTranslation()

  return (
    <Form initialValues={initialValues} onFinish={onFinish} layout={"vertical"} >
      <Form.Item
        label={t("form:label.firstname")}
        name="firstname"
        rules={[
          {
            required: true,
            message: t("form:error.firstnameRequired"),
          },
        ]}
      >
        <Input placeholder={t("form:label.firstname")} size="large" />
      </Form.Item>

      <Form.Item
        label={t("form:label.lastname")}
        name="lastname"
        rules={[
          {
            required: true,
            message: t("form:error.lastnameRequired"),
          },
        ]}
      >
        <Input placeholder={t("form:label.lastname")} size="large" />
      </Form.Item>

      <Form.Item
        label={t("form:label.email")}
        name="email"
        rules={[
          {
            required: true,
            message: t("form:error.emailRequired"),
          },
          {
            type: "email",
            message: t("form:error.emailNotValid"),
          },
        ]}
      >
        <Input placeholder={t("form:label.email")} size="large" />
      </Form.Item>

      <Form.Item
        label={t("form:label.message")}
        name="message"
        rules={[
          { required: true, message: t("form:error.messageRequired") },
          { max: 1000 },
        ]}
      >
        <Input.TextArea
          showCount
          maxLength={1000}
          rows={10}
          placeholder={t("form:label.message")}
          size="large"
        />
      </Form.Item>

      <div className="form-submit-button-wrapper">
        <Form.Item>
          <Button
            size="large"
            className="green"
            type="primary"
            htmlType="submit"
          >
            {t("button:send")}
          </Button>
        </Form.Item>
      </div>
    </Form>
  )
}

export default ContactForm
